import React from 'react';
import pureEnhance from '@ux/pure-enhance';
import {
  withProps,
} from '@team-griffin/rehook';
import Parallax from './Parallax';
import Static from './Static';
import createImageAlt from 'presentation/utils/createImageAlt';
import stylesheet, { Styles } from './Layer.stylesheet';
import * as r from 'ramda';

export interface Layer {
  imageUrl?: string,
  focus: string,
  webpUrl?: string,
  fx: {
    type: string
  },
}

interface OuterProps {
  layer: Layer,
}
export interface InnerProps extends OuterProps {
  styles: Styles,
  isParallax: boolean,
}
export const PureLayer = ({
  styles,
  layer,
  isParallax,
}: InnerProps) => (
  <div
    style={styles.root}
    role="img"
    aria-label={createImageAlt(layer)}
  >
    <Choose>
      <When condition={isParallax}>
        <div style={styles.plx}>
          <Parallax
            layer={layer}
          />
        </div>
      </When>
      <Otherwise>
        <Static layer={layer}/>
      </Otherwise>
    </Choose>
  </div>
);

export const enhance = pureEnhance<InnerProps, OuterProps>(
  'Layer',
  {
    hooks: [
      withProps((props: OuterProps) => ({
        isParallax: r.pathEq([ 'layer', 'fx', 'type' ], 'parallax', props),
      })),
      withProps(stylesheet),
    ],
  }
);

export default enhance(PureLayer);
