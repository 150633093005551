import { useStyles } from '@team-griffin/stylr';
import { getTitleColor, getBodyColor } from 'presentation/utils/colorway';
import { gu2px } from '@ux/theme-utils';
import cssSides, { Side } from '@team-griffin/css-sides';
import { modifier } from '@ux/style-utils';
import { Alignment } from 'domain/constants/alignment';
import { smallprint } from '@ux/type-size';
import * as r from 'ramda';
import { InnerProps } from './Body';
import { CSSProperties } from 'react';


export interface Styles {
  root: CSSProperties,
  precursor: CSSProperties,
  title: CSSProperties,
  subtitle: CSSProperties,
  ctas: CSSProperties,
  cta: CSSProperties,
  footerText: CSSProperties,
}
export default ({
  theme,
  colorway,
  theme: {
    spacing,
    typography,
  },
  layout: {
    alignment,
  },
  isMinSm,
}: InnerProps) => useStyles({
  default: {
    root: {
      position: 'relative',
    },
    precursor: {
      color: getTitleColor(theme, colorway),
    },
    title: {
      color: getTitleColor(theme, colorway),
    },
    subtitle: {
      color: getBodyColor(theme, colorway),
      marginTop: gu2px(2, spacing),
    },
    ctas: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: gu2px(2, spacing),
      overflow: 'hidden',
    },
    cta: {
      marginTop: gu2px(1, spacing),
    },
    footerText: {
      marginTop: gu2px(1, spacing),
      ...smallprint(typography),
      color: getBodyColor(theme, colorway),
      opacity: 0.9,
    },
  },

  isMinSm: {
    ctas: {
      flexDirection: 'row',
      ...cssSides('margin', Side.X, r.negate(gu2px(0.5, spacing))),
    },
    cta: {
      marginBottom: 0,
      ...cssSides('margin', Side.X, gu2px(0.5, spacing)),
    },
  },

  [modifier('alignment', Alignment.CENTER)]: {
    root: {
      textAlign: 'center',
    },
    footerText: {
      textAlign: 'center',
    },
    ctas: {
      justifyContent: 'center',
    },
  },
}, [
  { isMinSm },
  { alignment },
]);