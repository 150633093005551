import { useStyles } from '@team-griffin/stylr';
import { getActiveAnchor, halfContainerWidth } from '../styles';
import { breakpoints } from '@ux/responsive';
import cssSides, { Side } from '@team-griffin/css-sides';
import { gu2px } from '@ux/theme-utils';
import ribbonPadding from 'presentation/utils/ribbonPadding';
import { Side as TilesSide } from '../constants';
import { percentage } from '@ux/style-utils';
import { InnerProps } from './WithTiles';
import { CSSProperties } from 'react';

export interface Styles {
  content: CSSProperties,
  body: CSSProperties,
  container: CSSProperties,
  row: CSSProperties,
}

export default ({
  layout,
  theme,
  isMinSm,
  isMinMd,
  isMinLg,
  isMinXl,
}: InnerProps) => {
  const {
    spacing,
  } = theme;

  const {
    anchor,
    tilesSide,
  } = layout;

  const activeAnchor = getActiveAnchor(anchor, {
    isMinSm,
    isMinMd,
    isMinLg,
    isMinXl,
  });

  return useStyles({
    default: {
      content: {
        flex: 1,
        flexBasis: 'auto',
        justifyContent: activeAnchor,
        display: 'flex',
        flexDirection: 'column',
        ...ribbonPadding({
          spacing,
          isMinSm,
          isMinLg,
        }),
      },
      body: {
        flex: 1,
        flexBasis: 'auto',
      },
    },

    isMinLg: {
      container: {
        ...cssSides('padding', Side.X, gu2px(1, spacing)),
        display: 'flex',
        flex: 1,
        flexBasis: 'auto',
        // Safari is not filling 100% height of flex parent
        // in nested flex containers
        webkitFlexBasis: 0,
        width: percentage(100),
        maxWidth: percentage(100),
      },
      row: {
        width: percentage(100),
      },
      content: {
        marginLeft: 'auto',
        maxWidth: halfContainerWidth(breakpoints.lg, spacing),
        height: percentage(100),
      },
    },

    isMinXl: {
      content: {
        maxWidth: halfContainerWidth(breakpoints.xl, spacing),
      },
    },

    lgStartTile: {
      content: {
        marginLeft: 0,
        marginRight: 'auto',
      },
    },
  }, [
    { isMinSm },
    { isMinLg },
    { isMinXl },
    { lgStartTile: tilesSide === TilesSide.START && isMinLg },
  ]);
};
