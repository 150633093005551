import React, { Fragment } from 'react';
import { Container } from '@ux/grid';
import pureEnhance from '@ux/pure-enhance';
import Background, { BackgroundArray } from '../Background';
import Body, { OuterProps as BodyProps } from '../Body';
import Scroll from '../Scroll';
import stylesheet, { Styles } from './Duo.stylesheet';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';
import { withProps } from '@team-griffin/rehook';
import { Theme } from '@ux/theme-utils';
import { Colorway } from 'domain/constants/colorway';
import { Layout } from 'domain/constants/layout';

interface OuterProps extends BodyProps {
  theme: Theme,
  layout: Layout,
  colorway: Colorway,
  hBaseline: number,
  showScroll: boolean,
  duoLayout: boolean,
  background: BackgroundArray,
  onScrollClick: (evt: any) => any,
}

export interface InnerProps extends OuterProps, BreakpointProps {
  styles: Styles,
}

export const PureBillboardDuo = ({
  styles,
  theme,
  layout,
  colorway,
  hBaseline,
  ctas,
  text,
  additionalText,
  footerText,
  onScrollClick,
  showScroll,
  ribbonId,
  background,
}: InnerProps) => (
  <Fragment>
    <Background
      theme={theme}
      background={background}
      layout={layout}
      colorway={colorway}
      duoLayout={true}
    />
    <div style={styles.content}>
      <div style={styles.duoLayout}>
        <Body
          theme={theme}
          colorway={colorway}
          ribbonId={ribbonId}
          text={text}
          footerText={footerText}
          additionalText={additionalText}
          ctas={ctas}
          layout={layout}
          hBaseline={hBaseline}
        />
      </div>
    </div>
    <If condition={showScroll}>
      <Container>
        <div style={styles.duoLayout}>
          <Scroll
            theme={theme}
            onScrollClick={onScrollClick}
            layout={layout}
            colorway={colorway}
          />
        </div>
      </Container>
    </If>
  </Fragment>
);

const enhance = pureEnhance<InnerProps, OuterProps>('BillboardDuo', [
  withBreakpointsHook,
  withProps(stylesheet),
]);

export default enhance(PureBillboardDuo);
