import * as r from 'ramda';
import { gu2px } from '@ux/theme-utils';
import flexAnchors, { AnchorArray } from 'presentation/utils/flexAnchors';
import findResponsiveItem from 'presentation/utils/findResponsiveItem';
import { Anchor } from 'domain/constants/anchor';
import { BreakpointProps } from '@ux/responsive';

/**
 * Two layouts: Tiles and Duo, require separate containers to site
 * next to each other, bleed to the far edge, but still maintain the
 * site width rules of a single container. This function returns half
 * the width of the container size
 */
export const halfContainerWidth = (width: number, spacing: number) => r.pipe(
  // add the extra gu of spacing between the two items
  r.add(gu2px(1, spacing)),
  // account for the additional padding at either side
  r.divide(r.__, 2),
  // remove the accounted for spacing
  r.subtract(r.__, gu2px(1, spacing)),
)(width);


export const getActiveAnchor = (anchor: AnchorArray, breakpoints: BreakpointProps) => {

  const getActive = (from: AnchorArray) => findResponsiveItem(
    breakpoints,
    from,
  );

  const activeAnchor = getActive(
    flexAnchors([ Anchor.TOP, null, null ], anchor),
  );

  return activeAnchor;
};

export default null;