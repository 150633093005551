import { useStyles } from '@team-griffin/stylr';
import getBreakpoints from 'presentation/utils/getBreakpoints';
import { getActiveAnchor, halfContainerWidth } from '../styles';
import { breakpoints } from '@ux/responsive';
import cssSides, { Side } from '@team-griffin/css-sides';
import { gu2px } from '@ux/theme-utils';
import * as r from 'ramda';
import { percentage } from '@ux/style-utils';
import { InnerProps } from './Duo';
import { CSSProperties } from 'react';

export interface Styles {
  content: CSSProperties,
  duoLayout: CSSProperties,
}
export default (props: InnerProps) => {
  const {
    isMinSm,
    isMinMd,
    isMinLg,
    isMinXl,
    layout,
    duoLayout,
    theme,
  } = props;

  const {
    anchor,
  } = layout;

  const startDuoLayout = r.pathOr(false, [ 'layout', 'startDuoLayout' ], props);
  // @todo this prop isn't set properly due to how ribbon raid loops and creates duo
  // const endDuoLayout = r.pathOr(false, [ 'layout', 'endDuoLayout' ], props);

  const breakpointProps = getBreakpoints(props);
  const activeAnchor = getActiveAnchor(anchor, breakpointProps);

  return useStyles({
    default: {
      content: {
        flex: 1,
        flexBasis: 'auto',
        justifyContent: activeAnchor,
        display: 'flex',
        flexDirection: 'column',
      },
      duoLayout: {
        ...cssSides('padding', Side.X, gu2px(1, theme.spacing)),
        ...cssSides('margin', Side.X, 'auto'),
        width: percentage(100),
        maxWidth: percentage(100),
      },
    },

    isMinSm: {
      duoLayout: {
        maxWidth: breakpoints.sm - gu2px(1, theme.spacing),
      },
    },

    isDuoLayoutMd: {
      duoLayout: {
        maxWidth: halfContainerWidth(breakpoints.md, theme.spacing),
        marginLeft: 0,
      },
    },
    isDuoLayoutLg: {
      duoLayout: {
        maxWidth: halfContainerWidth(breakpoints.lg, theme.spacing),
      },
    },
    isDuoLayoutXl: {
      duoLayout: {
        maxWidth: halfContainerWidth(breakpoints.xl, theme.spacing),
      },
    },
    startDuoLayout: {
      duoLayout: {
        marginRight: 0,
        marginLeft: 'auto',
      },
    },
  }, [
    { anchor },
    { isMinSm },
    { isDuoLayoutMd: duoLayout && isMinMd },
    { isDuoLayoutLg: duoLayout && isMinLg },
    { isDuoLayoutXl: duoLayout && isMinXl },
    { startDuoLayout },
  ]);
};
