import React, { Fragment } from 'react';
import { withProps } from '@team-griffin/rehook';
import { Container, Row, Column, Justify } from '@ux/grid';
import pureEnhance from '@ux/pure-enhance';
import Background, { BackgroundArray } from '../Background';
import Body, { OuterProps as BodyProps } from '../Body';
import Scroll from '../Scroll';
import { Alignment } from 'domain/constants/alignment';
import stylesheet, { Styles } from './Generic.stylesheet';

interface OuterProps extends BodyProps {
  showScroll: boolean,
  background: BackgroundArray,
  onScrollClick: (evt: any) => any,
}
export interface InnerProps extends OuterProps, BreakpointProps {
  styles: Styles,
  justify: Justify,
}

import * as r from 'ramda';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';

export const PureBillboardGeneric = ({
  styles,
  theme,
  layout,
  colorway,
  hBaseline,
  ctas,
  text,
  additionalText,
  footerText,
  justify,
  onScrollClick,
  showScroll,
  ribbonId,
  background,
}: InnerProps) => (
  <Fragment>
    <Background
      theme={theme}
      background={background}
      layout={layout}
      colorway={colorway}
    />
    <div style={styles.content}>
      <Container>
        <Row
          vblock={true}
          justify={justify}
        >
          <Column
            xs={12}
            md={8}
            lg={6}
          >
            <Body
              theme={theme}
              colorway={colorway}
              ribbonId={ribbonId}
              text={text}
              additionalText={additionalText}
              footerText={footerText}
              ctas={ctas}
              layout={layout}
              hBaseline={hBaseline}
            />
          </Column>
        </Row>
      </Container>
    </div>
    <If condition={showScroll}>
      <Container>
        <Scroll
          theme={theme}
          onScrollClick={onScrollClick}
          layout={layout}
          colorway={colorway}
        />
      </Container>
    </If>
  </Fragment>
);

const enhance = pureEnhance<InnerProps, OuterProps>('BillboardGeneric', [
  withBreakpointsHook,
  withProps((ownerProps: OuterProps) => ({
    justify: r.ifElse(
      r.pathEq([ 'layout', 'alignment' ], Alignment.CENTER),
      r.always(Justify.CENTER),
      r.always(null),
    )(ownerProps),
  })),
  withProps(stylesheet),
]);

export default enhance(PureBillboardGeneric);
