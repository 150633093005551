import React from 'react';
import {
  withProps,
} from '@team-griffin/rehook';
import stylesheet, { Styles } from './Parallax.stylesheet';
import Plx from 'react-plx';
import * as r from 'ramda';
import withLanguage from 'presentation/hocs/withLanguage';
import { Layer } from './Layer';
import pureEnhance from '@ux/pure-enhance';

interface OuterProps {
  layer: Layer,
}

export interface InnerProps extends OuterProps {
  currentLanguage: string,
  styles: Styles,
  data: {
    start: string,
    duration: string,
    properties: [
      {
        startValue: number,
        endValue: number,
        property: string,
      },
    ],
  }
}
const getParallaxData = ({
  layer,
}: OuterProps) => {
  return [{
    start: 'self',
    // @TODO: this value will have to be determined by the ribbon's height.
    // We might have to use react-height here to help us.
    // ---
    // It is double the value of the viewheight to ensure that
    // it keeps performing the parallax scrolling past.
    // 200vh was an arbitary number. This will have to be tweaked with Matt's
    // consent.Make sure that when you tweak this number you also tweak
    // the height calc in the stylesheet
    duration: '200vh',
    properties: [
      {
        startValue: r.ifElse(
          r.equals('parallax'),
          r.always(r.negate(400)),
          r.always(0)
        )(layer.fx.type),
        endValue: 0,
        property: 'translateY',
      },
    ],
  }];
};

export const PureParallax = ({
  styles,
  data,
}: InnerProps) => (
  <Plx
    parallaxData={data}
    style={styles.image}
  />
);

export const enhance = pureEnhance<InnerProps, OuterProps>(
  'Parallax',
  {
    hocs: [
      withLanguage,
    ],
    hooks: [
      withProps((props: OuterProps) => ({
        data: getParallaxData(props),
      })),
      withProps(stylesheet),
    ],
  }
);

export default enhance(PureParallax);
