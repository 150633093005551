import { useStyles } from '@team-griffin/stylr';
import { percentage } from '@ux/style-utils';
import { InnerProps } from './Tiles';
import { CSSProperties } from 'react';

export interface Styles {
  root: CSSProperties,
  tile: CSSProperties,
}
export default ({
  isMinMd,
  isMinLg,
}: InnerProps) => {
  return useStyles({
    default: {
      tile: {
        flex: 1,
        flexBasis: 'auto',
        // Safari is not filling 100% height of flex parent
        // in nested flex containers
        WebkitFlexBasis: 0,
        position: 'relative',
      },
    },

    isMinMd: {
      root: {
        flexDirection: 'row',
        display: 'flex',
      },
      tile: {
        width: percentage(50),
      },
    },
    isMinLg: {
      root: {
        flexDirection: 'column',
        height: percentage(100),
      },
      tile: {
        width: percentage(100),
      },
    },
  }, [
    { isMinMd },
    { isMinLg },
  ]);
};
