import { useStyles } from '@team-griffin/stylr';
import getBreakpoints from 'presentation/utils/getBreakpoints';
import { getActiveAnchor } from '../styles';
import { InnerProps } from './Generic';
import { CSSProperties } from 'react';

export interface Styles {
  content: CSSProperties,
}

export default (props: InnerProps) => {
  const {
    layout,
  } = props;

  const {
    anchor,
  } = layout;


  const breakpointProps = getBreakpoints(props);
  const activeAnchor = getActiveAnchor(anchor, breakpointProps);

  return useStyles({
    default: {
      content: {
        flex: 1,
        flexBasis: 'auto',
        justifyContent: activeAnchor,
        display: 'flex',
        flexDirection: 'column',
      },
    },
  }, []);
};
