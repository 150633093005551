import { useStyles } from '@team-griffin/stylr';
import { percentage } from '@ux/style-utils';
import { CSSProperties } from 'react';

export interface Styles {
  videoWrapper: CSSProperties,
  video: CSSProperties,
  fallbackVideoImage: CSSProperties,
}

export default () => {
  return useStyles({
    default: {
      videoWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
      },
      video: {
        objectFit: 'cover',
        objectPosition: 'center center',
        width: percentage(100),
        height: percentage(100),
      },
      fallbackVideoImage: {
        minHeight: percentage(100),
        minWidth: percentage(100),
        position: 'absolute',
        top: 0,
      },
    },
  }, []);
};
