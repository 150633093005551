import React from 'react';
import {
  withProps,
} from '@team-griffin/rehook';
import stylesheet, { Styles } from './Wash.stylesheet';
import pureEnhance from '@ux/pure-enhance';
import { Theme } from '@ux/theme-utils';
import { Colorway } from 'domain/constants/colorway';
import { Layout } from 'domain/constants/layout';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';

interface OuterProps {
  theme: Theme,
  colorway: Colorway,
  layout: Layout,
  hasTiles: boolean,
  duoLayout: boolean,
}
export interface InnerProps extends OuterProps, BreakpointProps {
  styles: Styles,
}

export const PureWash = ({
  styles,
}: InnerProps) => (
  <div style={styles.root}/>
);

export const enhance = pureEnhance<InnerProps, OuterProps>(
  'Wash',
  {
    hooks: [
      withBreakpointsHook,
      withProps(stylesheet),
    ],
  },
);

export default enhance(PureWash);
