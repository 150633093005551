import React from 'react';
import pureEnhance from '@ux/pure-enhance';
import PaddingBox from '@ux/padding-box';
import { Button, Kind, Against } from '@ux/button';
import Message from '@ux/message';
import { Colorway } from 'domain/constants/colorway';
import { withProps } from '@team-griffin/rehook';
import stylesheet, { Styles } from './Scroll.stylesheet';
import * as r from 'ramda';
import { Theme } from '@ux/theme-utils';
import { Layout } from 'domain/constants/layout';

interface OuterProps {
  theme: Theme,
  colorway: Colorway,
  onScrollClick: (evt: any) => any,
  layout: Layout,
}
export interface InnerProps extends OuterProps {
  styles: Styles,
  scrollAgainst: Against,
}
export const PureBillboardScroll = ({
  theme,
  onScrollClick,
  scrollAgainst,
  styles,
}: InnerProps) => (
  <PaddingBox
    theme={theme}
    style={styles.scroller}
  >
    <Button
      type="button"
      onClick={onScrollClick}
      against={scrollAgainst}
      kind={Kind.TERTIARY}
      glyph="chevronDown"
    >
      <Message id="usf.ribbons.billboard.scroll"/>
    </Button>
  </PaddingBox>
);

const enhance = pureEnhance<InnerProps, OuterProps>(
  'BillboardScroll',
  {
    hooks: [
      withProps((ownerProps: OuterProps) => ({
        scrollAgainst: r.when(
          r.equals(Colorway.PRIMARY_GRADIENT),
          r.always(Colorway.PRIMARY),
        )(ownerProps.colorway),
      })),
      withProps(stylesheet),
    ],
  }
);

export default enhance(PureBillboardScroll);
