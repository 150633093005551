import React, { Fragment, ReactNode } from 'react';
import pureEnhance from '@ux/pure-enhance';
import { withProps, defaultProps } from '@team-griffin/rehook';
import { PreHeading01, Heading01, SubHeading01 } from '@ux/typography';
import PaddingBox, { Side } from '@ux/padding-box';
import CallToAction from 'components/common/CallToAction';
import { Size as ButtonSize } from '@ux/button';
import Shortcode, { ShortcodeProps } from 'components/Shortcode';
import { getAgainst } from 'presentation/utils/colorway';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';
import stylesheet, { Styles } from './Body.stylesheet';
import { isNotNilOrEmpty } from '@team-griffin/capra';
import * as r from 'ramda';
import { Theme } from '@ux/theme-utils';
import { Layout } from 'domain/constants/layout';
import { Colorway } from 'domain/constants/colorway';
import { CTA } from 'core/ribbons/cta';


export interface OuterProps {
  theme: Theme,
  hBaseline?: number,
  text: {
    precursor?: ShortcodeProps,
    title?: ShortcodeProps,
    body?: ShortcodeProps,
  },
  additionalText?: string|ReactNode,
  footerText?: string|ReactNode,
  ctas: CTA[],
  colorway: Colorway,
  layout: Layout,
  ribbonId: string,
}
export interface DefaultProps extends OuterProps {
  hBaseline: number,
}
export interface InnerProps extends DefaultProps, BreakpointProps {
  styles: Styles,
  hasPrecursor: boolean,
  hasTitle: boolean,
  hasBodyText: boolean,
  hasCta: boolean,
  hasFooterText: boolean,
}

declare const cta: CTA;
declare const i: number;

const PureBody = ({
  theme,
  styles,
  hBaseline,
  hasPrecursor,
  hasTitle,
  hasBodyText,
  hasCta,
  hasFooterText,
  text,
  additionalText,
  footerText,
  ctas,
  colorway,
  layout,
  ribbonId,
  isMinSm,
}: InnerProps) => (
  <Fragment>
    <PaddingBox
      theme={theme}
      style={styles.root}
    >
      <Choose>
        <When condition={hasPrecursor}>
          <PreHeading01
            theme={theme}
            style={styles.precursor}
            depth={hBaseline}
          >
            <Shortcode
              theme={theme}
              against={colorway}
              code={text.precursor}
            />
          </PreHeading01>
          <If condition={hasTitle}>
            <Heading01
              theme={theme}
              depth={hBaseline + 1}
              style={styles.title}
            >
              <Shortcode
                theme={theme}
                against={colorway}
                code={text.title}
              />
            </Heading01>
          </If>
        </When>
        <Otherwise>
          <If condition={hasTitle}>
            <Heading01
              theme={theme}
              depth={hBaseline}
              style={styles.title}
            >
              <Shortcode
                theme={theme}
                against={colorway}
                code={text.title}
              />
            </Heading01>
          </If>
        </Otherwise>
      </Choose>
      <If condition={hasBodyText}>
        <SubHeading01
          theme={theme}
          component="p"
          style={styles.subtitle}
        >
          <Shortcode
            theme={theme}
            against={colorway}
            layout={layout}
            code={text.body}
          />
        </SubHeading01>
      </If>
      {additionalText}
      <If condition={hasCta}>
        <div style={styles.ctas}>
          <For
            each="cta"
            index="i"
            of={ctas}
          >
            <span
              style={styles.cta}
              key={i}
            >
              <CallToAction
                theme={theme}
                against={getAgainst(colorway)}
                block={!(isMinSm)}
                size={ButtonSize.LG}
                ribbonId={ribbonId}
                index={i}
                {...cta}
              />
            </span>
          </For>
        </div>
      </If>
    </PaddingBox>
    <If condition={hasFooterText}>
      <PaddingBox
        theme={theme}
        side={Side.X}
        style={styles.footerText}
      >
        {footerText}
      </PaddingBox>
    </If>
  </Fragment>
);

const enhance = pureEnhance('BillboardBody', {
  hooks: [
    defaultProps({
      hBaseline: 1,
    }),
    withProps((ownerProps: DefaultProps) => ({
      hasBodyText: r.pathSatisfies(
        isNotNilOrEmpty,
        [ 'text', 'body' ],
        ownerProps,
      ),
      hasCta: r.propSatisfies(
        isNotNilOrEmpty,
        'ctas',
      )(ownerProps),
      hasPrecursor: r.pathSatisfies(
        isNotNilOrEmpty,
        [ 'text', 'precursor' ],
        ownerProps,
      ),
      hasTitle: r.pathSatisfies(
        isNotNilOrEmpty,
        [ 'text', 'title' ],
        ownerProps,
      ),
      hasFooterText: r.propSatisfies(
        isNotNilOrEmpty,
        'footerText',
      )(ownerProps),
    })),
    withBreakpointsHook,
    withProps(stylesheet),
  ],
});

export default enhance(PureBody);