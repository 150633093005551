import React, { Fragment } from 'react';
import { Container, Row, Column, Direction, Justify } from '@ux/grid';
import pureEnhance from '@ux/pure-enhance';
import Background, { BackgroundArray } from '../Background';
import Body, { OuterProps as BodyProps } from '../Body';
import Scroll from '../Scroll';
import Tiles from '../Tiles';
import PaddingBox, { Side } from '@ux/padding-box';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';
import { withProps } from '@team-griffin/rehook';
import { Side as TilesSide } from '../constants';
import stylesheet, { Styles } from './WithTiles.stylesheet';
import * as r from 'ramda';
import { TileProps } from '../Tile';
import { isNotNilOrEmpty } from 'crosscutting/ramda';

interface OuterProps extends BodyProps {
  showScroll: boolean,
  background: BackgroundArray,
  onScrollClick: (evt: any) => any,
  justify: Justify,
  tiles: TileProps[],
}
export interface InnerProps extends OuterProps, BreakpointProps {
  styles: Styles,
  direction: Direction,
  hasFooterText: boolean,
}
export const PureBillboardTiles = ({
  styles,
  theme,
  layout,
  colorway,
  hBaseline,
  ctas,
  text,
  additionalText,
  footerText,
  tiles,
  onScrollClick,
  justify,
  showScroll,
  ribbonId,
  hasFooterText,
  background,
  direction,
  isMinLg,
}: InnerProps) => (
  <Fragment>
    <Background
      theme={theme}
      background={background}
      layout={layout}
      colorway={colorway}
      hasTiles={true}
    />
    <Choose>
      <When condition={isMinLg}>
        <div style={styles.container}>
          <div style={styles.row}>
            <Row
              vblock={true}
              justify={justify}
              direction={direction}
            >
              <Column
                lg={6}
                gutters={false}
              >
                <div style={styles.content}>
                  <Container>
                    <Body
                      theme={theme}
                      colorway={colorway}
                      ribbonId={ribbonId}
                      text={text}
                      additionalText={additionalText}
                      footerText={footerText}
                      ctas={ctas}
                      layout={layout}
                      hBaseline={hBaseline}
                    />
                    <If condition={showScroll}>
                      <Scroll
                        theme={theme}
                        onScrollClick={onScrollClick}
                        layout={layout}
                        colorway={colorway}
                      />
                    </If>
                  </Container>
                </div>
              </Column>
              <Column
                lg={6}
                gutters={false}
              >
                <Tiles
                  theme={theme}
                  layout={layout}
                  tiles={tiles}
                  hBaseline={hBaseline}
                />
              </Column>
            </Row>
          </div>
        </div>
      </When>
      <Otherwise>
        <div style={styles.content}>
          <Container>
            <Body
              theme={theme}
              colorway={colorway}
              ribbonId={ribbonId}
              text={text}
              additionalText={additionalText}
              ctas={ctas}
              layout={layout}
              hBaseline={hBaseline}
            />
            <If condition={hasFooterText}>
              <PaddingBox
                theme={theme}
                side={Side.X}
              >
                {footerText}
              </PaddingBox>
            </If>
            <If condition={showScroll}>
              <Scroll
                theme={theme}
                onScrollClick={onScrollClick}
                layout={layout}
                colorway={colorway}
              />
            </If>
          </Container>
        </div>
        <Tiles
          theme={theme}
          layout={layout}
          tiles={tiles}
          hBaseline={hBaseline}
        />
      </Otherwise>
    </Choose>
  </Fragment>
);

const enhance = pureEnhance<InnerProps, OuterProps>('BillboardTiles', [
  withBreakpointsHook,
  withProps((ownerProps: OuterProps) => ({
    direction: r.ifElse(
      r.pathEq([ 'layout', 'tilesSide' ], TilesSide.END),
      r.always(Direction.ROW),
      r.always(Direction.ROW_REVERSE),
    )(ownerProps),
    hasFooterText: r.propSatisfies(
      isNotNilOrEmpty,
      'footerText',
    )(ownerProps),
  })),
  withProps(stylesheet),
]);

export default enhance(PureBillboardTiles);
