import React from 'react';
import pureEnhance from '@ux/pure-enhance';
import { withProps } from '@team-griffin/rehook';
import { withBreakpointsHook } from '@ux/responsive';
import withLanguage from 'presentation/hocs/withLanguage';
import stylesheet, { Styles } from './CornerEmbellishments.stylesheet';
import { Theme } from '@ux/theme-utils';

export type cornerEmbellishments = {
  topRight?: string,
  bottomRight?: string,
  bottomLeft?: string,
  topLeft?: string
};
interface OuterProps {
  theme: Theme,
  cornerEmbellishments?: cornerEmbellishments,
}

export interface InnerProps extends OuterProps {
  styles: Styles,
  currentLanguage: string,
}
const PureCornerEmbellishments = ({
  styles,
}: InnerProps) => (
  <div style={styles.root}/>
);

const enhance = pureEnhance('CornerEmbellishments', {
  hocs: [
    withLanguage,
  ],
  hooks: [
    withBreakpointsHook,
    withProps(stylesheet),
  ],
});

export default enhance(PureCornerEmbellishments);