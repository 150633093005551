import { useStyles } from '@team-griffin/stylr';
import { UnsafeColorway as Colorway } from 'domain/constants/colorway';
import {
  modifier,
  percentage,
} from '@ux/style-utils';
import rgba from 'presentation/utils/rgba';
import { Alignment } from 'domain/constants/alignment';
import * as r from 'ramda';
import { InnerProps } from './Wash';
import { Color } from '@ux/theme-utils';
import { CSSProperties } from 'react';

export interface Styles {
  root: CSSProperties,
}
export default (props: InnerProps) => {
  const {
    theme,
    colorway,
  } = props;

  const {
    palette,
  } = theme;

  const wash = (clr: Color|string) => r.ifElse(
    r.propEq('colorway', Colorway.LIGHT),
    r.always(rgba(clr, 0.9)),
    r.always(rgba(clr, 0.75)),
  )(props);

  const gradient = (clr: Color|string) => `
    linear-gradient(
      to right,
      ${rgba(clr, 0.9)} 40%,
      ${rgba(clr, 0.8)} 50%,
      ${rgba(clr, 0)} 65%
    )`;

  /*
    Creates a gradient or semi opaque wash
    Use the wash on mobile, and gradient on tablet and above, unless:
    - we're center aligned text
    - we're a duo layout
    - we have tiles and are on desktop view
  */
  const generateBackground = r.pipe(
    (clr: Color|string) => r.cond([
      // eslint-disable-next-line max-len
      [ r.pathEq([ 'layout', 'alignment' ], Alignment.CENTER), () => wash(clr) ],
      [ r.propEq('duoLayout', true), () => wash(clr) ],
      [ r.both(
        r.propEq('hasTiles', true),
        r.propEq('isMinLg'),
      ), () => wash(clr) ],
      [ r.propEq('isMinMd', true), () => gradient(clr) ],
      [ r.T, () => wash(clr) ],
    ])(props),
  );

  return useStyles({
    default: {
      root: {
        position: 'absolute',
        top: 0,
        width: percentage(100),
        height: percentage(100),
      },
    },

    [modifier('colorway', Colorway.LIGHT)]: {
      root: {
        background: generateBackground('#ffffff'),
      },
    },

    [modifier('colorway', Colorway.NEUTRAL)]: {
      root: {
        background: generateBackground(palette.grey10),
      },
    },

    [modifier('colorway', Colorway.DARK)]: {
      root: {
        background: generateBackground('#000000'),
      },
    },

    [modifier('colorway', Colorway.PRIMARY)]: {
      root: {
        background: generateBackground(palette.primary),
      },
    },

    [modifier('colorway', Colorway.PRIMARY_GRADIENT)]: {
      root: {
        background: generateBackground(palette.primary),
      },
    },

    [modifier('colorway', Colorway.HIGHLIGHT)]: {
      root: {
        background: generateBackground(palette.highlight),
      },
    },

  }, {
    colorway,
  });

};
