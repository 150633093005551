import React, { Fragment } from 'react';
import {
  withProps,
} from '@team-griffin/rehook';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';
import stylesheet, { Styles } from './Background.stylesheet';
import Layer, { Layer as ImageLayer } from 'presentation/components/common/Layer';
import Wash from './Wash';
import withLanguage from 'presentation/hocs/withLanguage';
import urlLanguageParse from 'presentation/utils/urlLanguageParse';
import findResponsiveItem from 'presentation/utils/findResponsiveItem';
import getBreakpoints from 'presentation/utils/getBreakpoints';
import * as r from 'ramda';
import pureEnhance from '@ux/pure-enhance';
import { Layout } from 'domain/constants/layout';
import { Colorway } from 'domain/constants/colorway';
import { Theme } from '@ux/theme-utils';
import { ResponsiveSet } from 'core/ribbons/primitives';

interface Background {
  fallback: {
    url: string,
  },
  mp4?: string,
  webm?: string,
}
export type BackgroundArray = ResponsiveSet<Background|ImageLayer>;

interface OuterProps {
  layout: Layout,
  background: BackgroundArray,
  duoLayout?: boolean,
  hasTiles?: boolean,
  colorway: Colorway,
  theme: Theme,
}
interface InnerProps extends OuterProps, BreakpointProps {
  styles: Styles,
  activeBackground: Background,
  backgroundType: string,
  currentLanguage: string,
}

declare const layer: ImageLayer;
declare const i: number;

export const PureBackground = ({
  styles,
  activeBackground,
  backgroundType,
  layout,
  duoLayout,
  hasTiles,
  colorway,
  theme,
  currentLanguage,
}: InnerProps) => {
  return (
    <Fragment>
      <If condition={backgroundType === 'video'}>
        <div style={styles.videoWrapper}>
          <video
            loop={true}
            muted={true}
            autoPlay={true}
            playsInline={true}
            style={styles.video}
            poster={urlLanguageParse(activeBackground.fallback.url, currentLanguage)}
          >
            <source
              src={activeBackground.mp4}
              type="video/mp4"
            />
            <source
              src={activeBackground.webm}
              type="video/webm"
            />
            <img
              loading="lazy"
              src={urlLanguageParse(activeBackground.fallback.url, currentLanguage)}
              alt="fallback"
              style={styles.fallbackVideoImage}
            />
          </video>
        </div>
      </If>
      <If condition={backgroundType === 'image'}>
        <For
          each="layer"
          of={activeBackground as any as any[]}
          index="i"
        >
          <Layer
            key={i}
            layer={layer}
          />
        </For>
      </If>
      <Wash
        theme={theme}
        colorway={colorway}
        layout={layout}
        duoLayout={duoLayout}
        hasTiles={hasTiles}
      />
    </Fragment>
  );
};

//we need this because of the withProps that uses findResponsiveItem
interface MiddleProps extends OuterProps, BreakpointProps {}
export const enhance = pureEnhance<InnerProps, OuterProps>(
  'Background',
  {
    hocs: [
      withLanguage,
    ],
    hooks: [
      withBreakpointsHook,
      withProps((ownerProps: MiddleProps) => ({
        ...r.pipe(
          (ownerProps: MiddleProps) => findResponsiveItem(
            getBreakpoints(ownerProps),
            ownerProps.background
          ), r.defaultTo({}),
          (activeBackground) => ({
            activeBackground,
            backgroundType: r.ifElse(
              Array.isArray,
              r.always('image'),
              r.always('video'),
            )(activeBackground),
          })
        )(ownerProps),
      })),
      withProps(stylesheet),
    ],
  }
);


export default enhance(PureBackground);
