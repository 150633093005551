import { useStyles } from '@team-griffin/stylr';
import longhand from '@team-griffin/css-longhand';
import { gu2px } from '@ux/theme-utils';
import urlLanguageParse from 'presentation/utils/urlLanguageParse';
import * as r from 'ramda';
import { InnerProps } from './CornerEmbellishments';
import { CSSProperties } from 'react';

export interface Styles {
  root: CSSProperties,
}
const cornerToPosition = r.cond([
  [ r.equals('topLeft'), r.always('0 0') ],
  [ r.equals('topRight'), r.always('right 0') ],
  [ r.equals('bottomLeft'), r.always('0 bottom') ],
  [ r.equals('bottomRight'), r.always('right bottom') ],
]) as (str: string) => string;

const gu2CornerSize = (gu: number, spacing?: number) => `${gu2px(gu, spacing)}px ${gu2px(gu, spacing)}px`;

const cornerSize = (props: InnerProps) => r.cond([
  [
    r.propEq('isMinMd', true),
    r.always(gu2CornerSize(6, props.theme.spacing)),
  ],
  [
    r.propEq('isMinSm', true),
    r.always(gu2CornerSize(5, props.theme.spacing)),
  ],
  [
    r.T,
    r.always(gu2CornerSize(4)),
  ],
])(props) as string;

const generateCornerImages = (props: InnerProps) => r.pipe(
  r.defaultTo({}),
  r.reject(r.isNil),
  r.toPairs,
  r.map(([ corner, url ]) => ({
    image: `url(${urlLanguageParse(url, props.currentLanguage)})`,
    size: cornerSize(props),
    position: cornerToPosition(corner),
    repeat: 'no-repeat',
  })),
)(props.cornerEmbellishments) as unknown as {
  image: string,
  size: string,
  position: string,
  repeat: string,
}[];

const cornerArrayToCssProperty = (
  prop: string,
  cornerImages: {
    image: string,
    size: string,
    position: string,
    repeat: string,
  }[],
//r.pluck wants (never, unknown) as props. I'm more speciifc
//@ts-ignore
) => r.join(',', r.pluck(prop, cornerImages));

export default (props: InnerProps) => {
  const cornerImages = generateCornerImages(props);

  return useStyles({
    default: {
      root: {
        position: 'absolute',
        pointerEvents: 'none',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        ...longhand('background', {
          size: cornerArrayToCssProperty('size', cornerImages),
          image: cornerArrayToCssProperty('image', cornerImages),
          position: cornerArrayToCssProperty('position', cornerImages),
          repeat: cornerArrayToCssProperty('repeat', cornerImages),
        }),
      },
    },
  }, []);
};