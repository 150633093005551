import { useStyles } from '@team-griffin/stylr';
import { gu2px } from '@ux/theme-utils';
import { getTitleColor, getBodyColor, getBackgroundColor } from 'presentation/utils/colorway';
import { modifier, percentage } from '@ux/style-utils';
import { Alignment } from 'domain/constants/alignment';
import cssSides, { Side } from '@team-griffin/css-sides';
import { breakpoints } from '@ux/responsive';
import { Side as TilesSide } from './constants';
import { halfContainerWidth } from './styles';
import ribbonPadding from 'presentation/utils/ribbonPadding';
import { InnerProps } from './Tile';
import { CSSProperties } from 'react';

export interface Styles {
  root: CSSProperties,
  container: CSSProperties,
  title: CSSProperties,
  subtitle: CSSProperties,
  box: CSSProperties,
  wrapperText: CSSProperties,
  wrapperIcon: CSSProperties,
  icon: CSSProperties,
}
export default ({
  theme,
  colorway,
  hover,
  layout,
  isMinSm,
  isMinMd,
  isMinLg,
  isMinXl,
  leftTile,
  rightTile,
}: InnerProps) => {
  const {
    alignment,
    tilesSide,
  } = layout;

  const {
    spacing,
  } = theme;

  return useStyles({
    default: {
      root: {
        flex: 1,
        flexBasis: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: isMinLg ? 'center' : 'flex-start',
        backgroundColor: getBackgroundColor(theme, colorway),
        textAlign: 'left',
        textDecoration: 'none',
        height: percentage(100),
        ...ribbonPadding({
          spacing,
          isMinSm,
          isMinLg,
          layout: {
            shrinkLeading: isMinLg && rightTile,
            shrinkTrailing: isMinLg && leftTile,
          },
        }),
      },
      container: {
        ...cssSides('padding', Side.X, gu2px(1, spacing)),
        ...cssSides('margin', Side.X, 'auto'),
        position: 'relative',
        width: percentage(100),
        maxWidth: percentage(100),
      },
      title: {
        color: getTitleColor(theme, colorway),
        paddingRight: gu2px(3, spacing),
      },
      subtitle: {
        color: getBodyColor(theme, colorway),
        marginTop: gu2px(2, spacing),
      },
      box: {
        display: 'flex',
      },
      wrapperText: {
        flex: 1,
        flexBasis: 'auto',
        paddingRight: gu2px(2, spacing),
      },
      wrapperIcon: {
        minWidth: gu2px(3, spacing),
        position: 'relative',
        paddingTop: gu2px(0.5, spacing),
      },
      icon: {
        color: getTitleColor(theme, colorway),
        opacity: 1,
        width: gu2px(3, spacing),
        position: 'absolute',
        right: 0,
      },
    },
    hover: {
      root: {
        textDecoration: 'none',
      },
      icon: {
        opacity: 1,
        marginRight: 0,
        animation: 'arrowAnimation 0.3s',
        animationTimingFunction: 'ease-in-out',
        animationDirection: 'normal',
        animationFillMode: 'forwards',
      },
    },

    [modifier('alignment', Alignment.CENTER)]: {
      root: {
        textAlign: 'center',
      },
    },

    isMinSm: {
      container: {
        maxWidth: breakpoints.sm - gu2px(1, spacing),
      },
    },

    isMinMd: {
      container: {
        // theu're side by side so need an additional 1GU removing
        maxWidth: halfContainerWidth(breakpoints.md, spacing),
      },
    },

    isMinLg: {
      container: {
        maxWidth: halfContainerWidth(breakpoints.lg, spacing),
      },
    },
    isMinXl: {
      container: {
        maxWidth: halfContainerWidth(breakpoints.xl, spacing),
      },
    },
    leftTileMedium: {
      container: {
        marginRight: 0,
      },
    },
    rightTileMedium: {
      container: {
        marginLeft: 0,
      },
    },
    leftTileLarge: {
      container: {
        marginRight: 'auto',
        marginLeft: 0,
      },
    },

    tilesStartLarge: {
      container: {
        marginLeft: 'auto',
        marginRight: 0,
      },
    },
  }, [
    { alignment },
    { hover },
    { alignment },
    { isMinSm },
    { isMinMd },
    { isMinLg },
    { isMinXl },
    { leftTileMedium: leftTile && isMinMd },
    { rightTileMedium: rightTile && isMinMd },
    { leftTileLarge: leftTile && isMinLg },
    { tilesStartLarge: isMinLg && tilesSide === TilesSide.START },
  ]);
};
