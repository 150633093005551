import { useStyles } from '@team-griffin/stylr';
import { percentage } from '@ux/style-utils';
import { CSSProperties } from 'react';

export interface Styles {
  root: CSSProperties,
  plx: CSSProperties,
}

export default () => useStyles({
  default: {
    // We wrap the actual parallax element in another
    // element as parallax uses `transform` and we can
    // not specify 2 of those one a single element.
    root: {
      position: 'absolute',
      top: 0,
      width: percentage(100),
      height: percentage(100),
      overflow: 'hidden',
    },
    plx: {
      // We add on 400px here as a buffer space to work with parallaxing.
      // Whatever the amount to parallax is set to * 2.
      height: `calc(${percentage(100)} + 400px)`,
      position: 'absolute',
      top: 0,
      width: percentage(100),
    },
  },
});
