import React, { Fragment, ReactNode } from 'react';
import pureEnhance from '@ux/pure-enhance';
import {
  withProps,
  defaultProps,
  withHandlers,
  withState,
} from '@team-griffin/rehook';
import Ribbon from '../Ribbon';
import findResponsiveItem from 'presentation/utils/findResponsiveItem';
import getBreakpoints from 'presentation/utils/getBreakpoints';
import visibilitySet from 'presentation/hocs/visibilitySet';
import { Fit, RibbonProps } from 'domain/constants/ribbons';
import { scroller, Element } from 'react-scroll';
import { ulid } from 'ulid';
import CornerEmbellishments, { cornerEmbellishments } from './CornerEmbellishments';
import BillboardGeneric from './layouts/Generic';
import BillboardDuo from './layouts/Duo';
import BillboardTiles from './layouts/WithTiles';
import errorBoundary from 'presentation/hocs/errorBoundary';
import { OuterProps as BodyProps } from './Body';
import * as r from 'ramda';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';
import { CTA } from 'core/ribbons/cta';

export interface OuterProps extends BodyProps, RibbonProps {
  cornerEmbellishments?: cornerEmbellishments,
}
interface DefaultProps extends OuterProps {
  ctas: CTA[],
  additionalText: string|ReactNode,
  footerText: string|ReactNode,
  duo: boolean,
  duoLayout: boolean,
}
interface MiddleProps extends DefaultProps, BreakpointProps {}
interface InnerProps extends MiddleProps {
  bottomScrollName: string,
  hasTiles: boolean,
}

const hasTwoTiles = r.pipe(r.length, r.equals(2));

const shouldShowScroll = (props: MiddleProps) => r.ifElse(
  r.propEq('isMinSm', true),
  r.pipe(
    r.path([ 'layout', 'fit' ]),
    (fit) => findResponsiveItem(
      getBreakpoints(props),
      fit,
    ),
    r.either(
      r.equals(Fit.FULLSCREEN),
      r.equals(Fit.FILL),
    ),
  ),
  r.always(false),
)(props);


const BillboardLayout = r.cond([
  [ r.prop('hasTiles'), (props) => <BillboardTiles {...props}/> ],
  [ r.prop('duoLayout'), (props) => <BillboardDuo {...props}/> ],
  [ r.T, (props) => <BillboardGeneric {...props}/> ],
]);

export const PureBillboard = ({
  id,
  theme,
  layout,
  loading,
  colorway,
  name,
  bottomScrollName,
  hasTiles,
  duoLayout,
  cornerEmbellishments,
  ...props
}: InnerProps) => (
  <Fragment>
    <Ribbon
      id={id}
      theme={theme}
      colorway={colorway}
      layout={layout}
      loading={loading}
      name={name}
      type="Billboard"
      flush={hasTiles}
      duoLayout={duoLayout}
    >
      <BillboardLayout
        theme={theme}
        colorway={colorway}
        layout={layout}
        ribbonId={id}
        hasTiles={hasTiles}
        duoLayout={duoLayout}
        {...props}
      />
      <CornerEmbellishments
        theme={theme}
        cornerEmbellishments={cornerEmbellishments}
      />
    </Ribbon>
    <Element name={bottomScrollName}/>
  </Fragment>
);


export const enhance = pureEnhance<InnerProps, OuterProps>(
  'Billboard',
  {
    hocs: [
      errorBoundary,
      visibilitySet,
    ],
    hooks: [
      defaultProps({
        ctas: [],
        additionalText: null,
        footerText: null,
        duo: false,
      }),
      withState('bottomScrollName', 'setBottomScrollName', () => {
        return ulid();
      }),
      withHandlers({
        onScrollClick: ({ bottomScrollName }: { bottomScrollName: string }) => () => {
          scroller.scrollTo(bottomScrollName, {
            duration: 500,
            delay: 100,
            smooth: true,
          });
        },
      }),
      withBreakpointsHook,
      withProps((ownerProps: MiddleProps) => ({
        showScroll: shouldShowScroll(ownerProps),
        hasTiles: r.both(
          r.propSatisfies(hasTwoTiles, 'tiles'),
          r.propEq('duo', false),
        )(ownerProps),
      })),
    ],
  },
);

export default enhance(PureBillboard);
