import { CSSProperties } from 'react';
import { useStyles } from '@team-griffin/stylr';
import { percentage } from '@ux/style-utils';
import urlLanguageParse from 'presentation/utils/urlLanguageParse';
import { InnerProps } from './Parallax';

export interface Styles {
  image: CSSProperties,
}
export default ({
  layer,
  currentLanguage,
}: InnerProps) => {

  return useStyles({
    default: {
      image: {
        width: percentage(100),
        height: percentage(100),
        backgroundImage: `url(${urlLanguageParse(layer.imageUrl, currentLanguage)})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      },
    },
  }, []);
};
