import React, { CSSProperties } from 'react';
import {
  withProps,
} from '@team-griffin/rehook';
import { percentage } from '@ux/style-utils';
import urlLanguageParse from 'presentation/utils/urlLanguageParse';
import withLanguage from 'presentation/hocs/withLanguage';
import { useStyles } from '@team-griffin/stylr';
import pureEnhance from '@ux/pure-enhance';
import { Layer } from './Layer';

interface OuterProps {
  layer: Layer,
}

interface InnerProps extends OuterProps {
  currentLanguage: string,
  imageUrl: string,
  styles: {
    image: CSSProperties,
  },
}

const stylesheet = ({
  layer,
  imageUrl,
}: InnerProps) => {

  return useStyles({
    default: {
      image: {
        width: percentage(100),
        height: percentage(100),
        backgroundSize: 'cover',
        backgroundPosition: layer.focus || 'center center',
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: 'no-repeat',
      },
    },
  });
};

export const PureStatic = ({
  styles,
}: InnerProps) => (
  <div
    role="presentation"
    style={styles.image}
  />
);

export const enhance = pureEnhance<InnerProps, OuterProps>(
  'Static',
  {
    hocs: [
      withLanguage,
    ],
    hooks: [
      withProps(({
        layer,
        currentLanguage,
      }: InnerProps) => {
        const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        const image = (isChrome === true && layer.webpUrl != null) ? layer.webpUrl : layer.imageUrl;
        const imageUrl = urlLanguageParse(image, currentLanguage);

        return {
          imageUrl,
        };
      }),
      withProps(stylesheet),
    ],
  },

);

export default enhance(PureStatic);
