import React from 'react';
import {
  withProps,
} from '@team-griffin/rehook';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';
import Tile, { TileProps } from './Tile';
import stylesheet, { Styles } from './Tiles.stylesheet';
import { gu2px, Theme } from '@ux/theme-utils';
import { Layout } from 'domain/constants/layout';
import pureEnhance from '@ux/pure-enhance';

interface OuterProps {
  theme: Theme,
  tiles: TileProps[],
  hBaseline: number,
  layout: Layout,
}
export interface InnerProps extends OuterProps, BreakpointProps {
  styles: Styles,
}
const tileAnimation = (spacing: number) => `@keyframes arrowAnimation {
  from {
    right: ${gu2px(3, spacing)}px;
    opacity: 0.1;
  }
  to {
    right: ${gu2px(0, spacing)}px;
    opacity: 1;
  }
}`;

declare const i: number;
declare const tile: TileProps;

export const PureTiles = ({
  theme,
  tiles,
  styles,
  hBaseline,
  layout,
}: InnerProps) => (
  <div style={styles.root}>
    <style>
      {tileAnimation(theme.spacing)}
    </style>
    <For
      each="tile"
      of={tiles}
      index="i"
    >
      <div
        style={styles.tile}
        key={i}
      >
        <Tile
          theme={theme}
          key={i}
          hBaseline={hBaseline}
          layout={layout}
          leftTile={i === 0}
          rightTile={i === 1}
          {...tile}
        />
      </div>
    </For>
  </div>
);


export const enhance = pureEnhance<InnerProps, OuterProps>(
  'Tiles',
  {
    hooks: [
      withBreakpointsHook,
      withProps(stylesheet),
    ],
  },

);

export default enhance(PureTiles);
