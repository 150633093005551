export enum Side {
  START = 'START',
  END = 'END',
}

export enum Layout {
  DUO = 'DUO',
  TILES = 'TILES',
}

export default null;