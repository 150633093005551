import { useStyles } from '@team-griffin/stylr';
import { gu2px } from '@ux/theme-utils';
import { Alignment } from 'domain/constants/alignment';
import { modifier } from '@ux/style-utils';
import { InnerProps } from './Scroll';
import { CSSProperties } from 'react';

export interface Styles {
  scroller: CSSProperties,
}
export default ({
  theme: {
    spacing,
  },
  layout: {
    alignment,
  },
}: InnerProps) => useStyles({
  default: {
    scroller: {
      paddingTop: gu2px(2, spacing),
    },
  },
  [modifier('alignment', Alignment.CENTER)]: {
    scroller: {
      textAlign: 'center',
    },
  },

}, [
  { alignment },
]);
