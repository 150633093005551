import * as r from 'ramda';
import { withProps } from '@team-griffin/rehook';
import { isInternalUrl, isHashUrl } from 'domain/url';
import { NavLink } from 'react-router-dom';

interface AnyProps {
  url?: string,
  action?: {
    url?: string,
    [key: string]: any,
  }
  [key: string]: any,
}

export type UrlComponentType = typeof NavLink | 'a' | 'div';
const withUrlComponent = withProps((props: AnyProps) => r.pipe(
  r.either(
    r.prop('url'),
    r.path([ 'action', 'url' ]),
  ),
  r.cond([
    [
      r.isNil,
      () => ({
        Component: 'div',
      }),
    ],
    [
      isInternalUrl,
      (url) => ({
        Component: NavLink,
        to: url,
      }),
    ],
    [
      isHashUrl,
      (url) => ({
        Component: NavLink,
        to: url,
      }),
    ],
    [
      r.T,
      (url) => ({
        Component: 'a',
        href: url,
      }),
    ],
  ]),
//@ts-ignore
)(props));

export default withUrlComponent;