import React from 'react';
import {
  branch,
} from 'recompose';
import PaddingBox from '@ux/padding-box';
import {
  Heading02,
  BodyCopy01,
} from '@ux/typography';
import { withProps } from '@team-griffin/rehook';
import Shortcode, { ShortcodeProps } from 'components/Shortcode';
import stylesheet, { Styles } from './Tile.stylesheet';
import Glyph from '@ux/glyphs';
import { gu2px, Theme } from '@ux/theme-utils';
import callToAction from 'presentation/hocs/callToAction';
import pureEnhance from '@ux/pure-enhance';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';
import * as r from 'ramda';
import withUrlComponent, { UrlComponentType } from 'presentation/hooks/withUrlComponent';
import { withHover } from '@team-griffin/stylr';
import { Colorway } from 'domain/constants/colorway';
import { Layout } from 'domain/constants/layout';

export interface TileProps {
  colorway: Colorway,
  type: string,
  text: {
    body?: ShortcodeProps,
    title?: ShortcodeProps,
  },
  action: {
    url: string,
    linkAttribution?: string,
  },
}
interface OuterProps extends TileProps {
  theme: Theme,
  hBaseline: number,
  layout: Layout,
  leftTile: boolean,
  rightTile: boolean,
}
export interface InnerProps extends OuterProps, BreakpointProps {
  styles: Styles,
  hover: boolean,
  Component: UrlComponentType,
}

export const PureTile = ({
  styles,
  theme,
  colorway,
  text,
  hBaseline,
  action,
  Component,
}: InnerProps) => {
  const {
    title,
    body,
  } = text;

  return (
    <Component
      style={styles.root}
      href={action.url}
      to={action.url}
      id={action.linkAttribution ? action.linkAttribution : null}
    >
      <div style={styles.container}>
        <PaddingBox
          theme={theme}
          style={styles.box}
        >
          <div style={styles.wrapperText}>
            <Heading02
              theme={theme}
              style={styles.title}
              depth={hBaseline + 1}
            >
              <Shortcode
                theme={theme}
                against={colorway}
                code={title}
              />
            </Heading02>
            <BodyCopy01
              theme={theme}
              component="p"
              style={styles.subtitle}
            >
              <Shortcode
                theme={theme}
                against={colorway}
                code={body}
              />
            </BodyCopy01>
          </div>
          <div style={styles.wrapperIcon}>
            <span style={styles.icon}>
              <Glyph
                icon="arrowRight"
                size={gu2px(3, theme.spacing)}
                color={styles.icon.color}
              />
            </span>
          </div>
        </PaddingBox>
      </div>
    </Component>
  );
};

export const enhance = pureEnhance<InnerProps, OuterProps>('Tile', {
  hocs: [
    callToAction,
    branch(
      r.path([ 'action', 'url' ]),
      //@ts-ignore
      withHover
    ),
  ],
  hooks: [
    withBreakpointsHook,
    withUrlComponent,
    withProps(stylesheet),
  ],
});

export default enhance(PureTile);